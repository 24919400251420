@tailwind base;
@tailwind components;
@tailwind utilities;

.pageBg{
    background-image: url('/public/Assets/Base-Color.png')
}

/* width */
::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #c5c5c5;
    border-radius: 5px;
  
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #491131;
    
    
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #491131;
    
  }

  /* Assets/fonts.css */
@font-face {
    font-family: 'Raleway';
    src: url('/public/Assets/Raleway/Raleway-Regular.ttf') format('truetype');
    font-weight: normal;
 
}



body {
    height: 100vh;
    width: 100vw;
    font-family: 'Raleway', sans-serif;
    overflow-x: hidden;
}

/* Specific usage */
h1, h2, h3 {
    font-family: 'Raleway', sans-serif;
    letter-spacing: 4px;
    /* font-weight: bold; */
}
p {
    font-family: 'Raleway', sans-serif;
    letter-spacing: 1.5px;
    /* text-align: justify; */

    /* font-weight: normal; */
}

.ftext{
    text-align: center;
}

